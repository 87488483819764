import { Link } from "gatsby"
import * as React from "react"

export const SpringOneExplore = () => (
  <div className="hero-springone-explore hero">
    <div className="container">
      <h1 className="has-text-weight-medium has-text-centered">
        <span>Get</span>&nbsp;
        <span>your</span>&nbsp;
        <span>talks</span>&nbsp;
        <span>ready:</span>&nbsp;
        <br />
        <span>Call</span>&nbsp;
        <span>for</span>&nbsp;
        <span>Papers</span>&nbsp;
        <span>is</span>&nbsp;
        <span>open</span>&nbsp;
      </h1>
      <div className="springone-text">
        <p className="has-text-centered is-size-5">
          Submit your talk for SpringOne@Explore by April 14
        </p>
        <div className="py-4 has-text-centered">
          <a
            href="https://springone.io?utm_source=spring-io-home"
            className="button is-spring mr-3"
          >
            Learn more
          </a>
        </div>
      </div>
    </div>
  </div>
)
