import { graphql } from "gatsby"
import * as React from "react"
import { DefaultBottom } from "../components/banner/DefaultBottom"
import { SpringOneExplore } from "../components/banner/SpringOneExplore"
import { HTMLContent } from "../components/common/Content"
import { Feature } from "../components/common/Feature"
import Visible from "../components/common/Visible"
import Layout from "../components/layout/Layout"

// props
interface Props {
  data: {
    page: {
      html: any
      frontmatter: {
        title: string
        description?: string
        keywords?: Array<string>
        news?: Array<{
          title: string
          url: string
        }>
        testimony: {
          text: string
          author: string
          image: string
          url: string
        }
        features: Array<{
          title: string
          description: string
          url: string
        }>
      }
    }
  }
}

const News = ({ news }: any) => (
  <>
    <span className="divider has-text-grey-light">|</span>
    <a className="mx-3 py-2 is-inline-block link-animate" href={news.url}>
      {news.title}
    </a>
  </>
)

// markup
const IndexTemplate = (props: Props) => {
  const news = props.data.page.frontmatter.news
  const features = props.data.page.frontmatter.features
  const testimony = props.data.page.frontmatter.testimony
  return (
    <Layout
      seo={{
        title: props.data.page.frontmatter.title,
        description: props.data.page.frontmatter.description,
        keywords: props.data.page.frontmatter.keywords,
      }}
      className="home"
    >
      <SpringOneExplore />
      {news && news?.length > 0 && (
        <div className="has-border-bottom py-3">
          <div className="container has-text-centered">
            <span className="tag is-primary mr-3">NEWS</span>
            {news?.map((item: any, index: number) => (
              <News key={`item-${index}`} news={item} />
            ))}
          </div>
        </div>
      )}
      <div className="container py-6">
        <div className="features">
          <h1 className="is-size-2 mb-6 mt-4 has-text-weight-medium">
            What Spring can do
          </h1>
          <Visible className="columns is-centered is-multiline features">
            {features.map((feature: any, index: number) => (
              <Feature item={feature} key={`feature-${index}`} />
            ))}
          </Visible>
        </div>

        <div className="code mt-6 pt-6">
          <div className="columns">
            <div className="column is-three-fifths pr-6">
              <Visible className="terminal">
                <pre className="language-java">
                  <code>
                    <span className="terminal-blue">
                      @SpringBootApplication
                    </span>
                    <br />
                    <span className="terminal-blue">@RestController</span>
                    <br />
                    <span className="terminal-green">public</span> class
                    DemoApplication {`{`}
                    <br />
                    <div className="indent">
                      <br />
                      <span className="terminal-blue">@GetMapping</span>(
                      <span className="terminal-lime">
                        &quot;/helloworld&quot;
                      </span>
                      )<br />
                      <span className="terminal-green">public</span>
                      {` String hello() {`}
                      <div className="indent">
                        <div className="typed-placeholder">
                          return
                          <span className="terminal-lime">"Hello World!"</span>;
                        </div>
                      </div>
                      <div className="indent typed"></div>
                      {`}`}
                    </div>
                    {`}`}
                  </code>
                </pre>
              </Visible>
            </div>
            <div className="column is-two-fifths4">
              <HTMLContent
                content={props.data.page.html}
                className={"markdown links-animate content"}
              ></HTMLContent>
            </div>
          </div>
        </div>

        <div className="testimony my-6 py-6">
          <img
            className="testimony-quote mb-5"
            alt={`Quote`}
            width={70}
            src="/img/extra/quote.svg"
          />
          <div className="testimony-text mb-5 is-size-4">{testimony.text}</div>
          <div className="testimony-author">
            <img src={testimony.image} alt={testimony.author} width={60} />
            <div className="has-text-weight-bold">{testimony.author}</div>
            <div className="links-animate">
              <a href="https://www.youtube.com/watch?v=mln3_o6qlBo?autoplay=1&autohide=1&showinfo=0&controls=1">
                Watch now
              </a>
            </div>
          </div>
        </div>
      </div>
      <DefaultBottom />
    </Layout>
  )
}

export default IndexTemplate

// graphQL queries
export const pageQuery = graphql`
  query IndexTemplate($id: String!) {
    page: markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        description
        keywords
        news {
          title
          url
        }
        testimony {
          text
          author
          image
          url
        }
        features {
          title
          description
          url
          icon
        }
      }
    }
  }
`
